import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Masthead from "../components/Masthead/Masthead";
import Services from "../components/Services/Services";
import OurClients from "../components/Clients/OurClients";
import ClientsHelp from "../components/Clients/ClientsHelp";
import WorkTogether from "../components/WorkTogether/WorkTogether";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";

class Index extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title={config.siteTitle} />
        <SEO />
        <Header />
        <Masthead />
        <Services list />
        <ClientsHelp />
        <OurClients />
        <WorkTogether />
        <Footer />
      </Layout>
    );
  }
}

export default Index;
