import React from "react";
import { StaticQuery, graphql } from "gatsby";
import ServiceRow from "./ServiceRow";
import ServiceCard from "./ServiceCard";

function Service({
  data: {
    allMarkdownRemark: { edges },
  },
  list,
  icons
}) {
  return (list && <>{edges.map(edge => <ServiceRow key={edge.node.frontmatter.id} data={edge.node.frontmatter} />)}</>) ||
    (icons && (
      <section className="container-fluid services-section components-section">
        <div className="container">
          <div className="row d-flex flex-row justify-content-center py-5">
            {edges.map(edge => <ServiceCard key={edge.node.frontmatter.id} data={edge.node.frontmatter} />)}
          </div>
        </div>
      </section>
    )) || null;
};

export default props => (
  <StaticQuery
    query={graphql`
      query ServicesQuery {
        allMarkdownRemark(
          limit: 2000
          sort: { fields: [frontmatter___order], order: ASC },
          filter: {fileAbsolutePath: {regex: "/services/"}}
        ) {
          edges {
            node {
              frontmatter {
                id
                linklabel
                link
                order
                title
                content
                color
                items {
                  title
                }
                cover {
                  childImageSharp {
                    fixed(width: 350, height: 350) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                  extension
                  publicURL
                }
                background {
                  childImageSharp {
                    fluid(maxWidth: 1920, maxHeight: 1080) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                  publicURL
                }
                icon {
                  childImageSharp {
                    fixed(width: 75, height: 75) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Service data={data} {...props} />}
  />
)
