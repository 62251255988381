import React from "react";
import Img from "gatsby-image";
import classNames from "classnames";
import { Link } from "gatsby";

export default function ServiceRow({ data }) {
  return (
    <section className="container-fluid components-section components-services" style={{ backgroundColor: data.color, backgroundImage: data.background ? `url(${data.background.publicURL})` : null }}>
      <div className="container pt-5 pb-5">
        <div className="row">
          {data.cover && (
            <div className="col-md-4 d-flex justify-content-center">
              {data.cover.extension === "svg" ?
                <img className="d-inline-block align-middle mr-2" alt="Toptive service" src={data.cover.publicURL} /> :
                <Img className="d-inline-block align-middle mr-2" alt="Toptive service" fixed={data.cover.childImageSharp.fixed} />
              }
            </div>
          )}
          <div className={classNames("col-12 col-md-8 mt-4", { "offset-md-4": !data.cover })}>
            <h1 className="text-uppercase">
              {data.title}
            </h1>
            <p className="pb-5">
              {data.content}
            </p>
            <Link to={"/services/" + data.id} className="float-right btn btn-outline-white" style={{ color: data.color }}>
              {data.linklabel}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
