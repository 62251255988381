/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
import React, { Fragment } from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";

export default function ServiceCard({ data }) {
  return (
    <div className="mx-3 my-4 text-center">
      <Link className="d-flex flex-column service-card" to={`/services/${data.id}`}>
        <h4 className="d-flex justify-content-start justify-content-sm-center align-items-center">
          <Img fixed={data.icon.childImageSharp.fixed} className="d-inline-block align-middle mr-2" alt="Toptive service" />
          <span>
            {data.title.split(" ").map((string, index, parts) => (
              <Fragment key={index}>
                {string}
                {index === (parts.length - 2) ? <br /> : ' '}
              </Fragment>
            ))}
          </span>
        </h4>
        <ul className="tt-timeline m-auto">
          {data.items.map((item, index) => <li key={index}>{item.title}</li>)}
        </ul>
      </Link>
    </div>
  );
}
