import React from "react";
import { Link } from "gatsby";

import transporterImg from "../../assets/images/transporter.png";

export default function Masthead() {
  return (
    <div className="masthead-container">

      <section className="container-fluid pb-md-5 position-relative">
        <div className="background-clip">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
            <defs>
              <linearGradient id="backgroundGradient" gradientUnits="userSpaceOnUse" x1="50" y1="100" x2="50" y2="0">
                <stop offset="0" style={{ stopColor: "#6903a7", stopOpacity: 1 }} />
                <stop offset="0.25" style={{ stopColor: "#5303a7", stopOpacity: 1 }} />
                <stop offset="1" style={{ stopColor: "#3d03a7", stopOpacity: 1 }} />
              </linearGradient>
            </defs>
            <polygon preserveAspectRatio="none" points="100,0 100,65 0,100 0,0" style={{ fill: "url(#backgroundGradient)", fillOpacity: 1 }} className="d-none d-md-block" />
            <polygon preserveAspectRatio="none" points="100,0 100,100 0,100 0,0" style={{ fill: "url(#backgroundGradient)", fillOpacity: 1 }} className="d-block d-md-none" />
          </svg>
        </div>


        <div className="container color-white">
          <div className="py-lg-5 py-md-2">
            <div className="offset-lg-1 col-lg-10">
              <div className="row">
                <div className="col col-lg-6 main-leyend d-flex flex-column justify-content-center">
                  <h1 className="color-capry text-uppercase text-center text-lg-left mt-0">
                    Your project
                    <span className="color-white">,</span>
                  </h1>
                  <h1 className="color-white text-uppercase text-center text-lg-left">
                    Our values
                    <span className="color-capry">.</span>
                  </h1>
                  <p className="text-center text-lg-left mb-4">
                    Our Top Tier Developers scale up your projects to meet its greatest capability.
                  </p>
                  <Link to="/about#values" className="text-center text-lg-left mb-0">
                    <span>Wanna see our values?</span>
                  </Link>
                </div>
                <div className="col col-lg-6 mt-3 text-center text-md-right transporter-img">
                  <div className="d-inline-block transporter-container position-relative">
                    <img alt="Toptive Developers" className="img-fluid" src={transporterImg} style={{ opacity: 1 }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="background-bottom-clip">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
            <defs>
              <linearGradient id="shadowGradient" gradientUnits="userSpaceOnUse" x1="50" y1="100" x2="0" y2="0">
                <stop offset="0" style={{ stopColor: "rgba(255, 255, 255, 1)", stopOpacity: 0 }} />
                <stop offset="1" style={{ stopColor: "rgba(0, 0, 0, 0.25)", stopOpacity: 1 }} />
              </linearGradient>
            </defs>
            <polygon preserveAspectRatio="none" points="77 8, 100 17, 100 70, 51 17" style={{ fill: "url(#shadowGradient)", fillOpacity: 1 }} />
          </svg>
        </div>
      </section>

      <section className="container-fluid bg-white py-5 mt-lg-5 build-together">
        <div className="container py-3 text-center">
          <div>
            <h1 className="text-uppercase">
              <span className="d-inline-flex flex-column">
                Let&apos;s build it better together.
                <div className="bottom-border" />
              </span>
            </h1>
          </div>
          <div className="row mt-4">
            <div className="col-12 col-md-10 offset-md-1">
              <p>
                Toptive is a software company based in Argentina with an excellent track record that has been developing high quality products for the past seven years.
              </p>
              <p className="my-5">
                We are here to help when you need!
              </p>
              <p>
                Whenever somethings go wrong or when you want to your business, we are always here. We are an outsourcing team whom turns problems into solutions.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
