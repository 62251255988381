import React from "react";
import { StaticQuery, Link, graphql } from "gatsby"
import Img from "gatsby-image";


function ClientsHelp({
  data: {
    allProjectsYaml: { edges },
  },
}) {
  return (
    <section className="container-fluid bg-white py-5 clients-help-secton">
      <div className="container py-3">
        <div className="text-center">
          <div>
            <h1 className="text-uppercase">
              <span className="d-inline-flex flex-column">
                See how we help our clients.
                <div className="bottom-border" />
              </span>
            </h1>
          </div>
          <div className="row mt-5">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <p>
                In Toptive, we embrace long-lasting relationships with our clients. We are well-trusted by our clients with the outstanding outsourcing skills and most importantly, our passion and commitment.
              </p>
            </div>
          </div>
        </div>
      </div>


      <div className="container mt-4 pb-3">

        <div className="row">
          {edges.map(edge => (
            <div key={edge.node.id} className="col-12 col-sm-8 offset-sm-2 col-md-4 offset-md-0 single-client mb-4">
              <Img className="img-fluid" fixed={edge.node.picture.childImageSharp.fixed} />
              <h4 className="mt-3 color-blueberry">{edge.node.name}</h4>
              <p>
              {edge.node.tech && edge.node.tech.map(tech => (
                <span
                  className="btn btn-tech"
                  key={tech}
                >
                {tech}
                </span>
              ))
              }
              </p>
              <p className="mt-3">{edge.node.description}</p>
            </div>
          ))}
        </div>

        <div className="row mt-3">
          <div className="col-12 text-center">
            <Link to="/work">
              <button type="button" className="btn btn-outline-dark">More great projects we did</button>            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}


export default props => (
  <StaticQuery
    query={graphql`
      query TopProjectsQuery {
        allProjectsYaml(limit: 3) {
          edges {
            node {
              id
              name
              short
              link
              description
              resume
              tech
              picture {
                childImageSharp {
                  fixed(width: 300) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <ClientsHelp data={data} {...props} />}
  />
)
