/* eslint-disable react/no-unescaped-entities */
import React from "react";

import comafiImg from "../../assets/images/companies/comafi.png";
import compassImg from "../../assets/images/companies/compass.png";
import PROImg from "../../assets/images/companies/PRO.png";
import senseImg from "../../assets/images/companies/sense 2.png";
import sensemobileImg from "../../assets/images/companies/sensemobile 2.png";
import tmaImg from "../../assets/images/companies/tma-logo.png";
import varixImg from "../../assets/images/companies/varix.png";

export default function OurClients() {
  return (
    <section className="container-fluid bg-gray py-5 our-clients-section">
      <div className="container py-3">
        <div className="text-center">
          <div>
            <h1 className="uppercase">Our clients</h1>
          </div>
          <div className="row mt-5">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <p>
                We work with companies of all shapes and sizes. Whether you’re an ad agency servicing multi-national brands, in-house art or marketing department, design firm, start-up, or small business owner we'd love to work with you.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid pb-3">
        <div className="row justify-content-center">
          <div className="col-auto my-3 mx-3 single-client">
            <img alt="Banco Comafi" src={comafiImg} />
          </div>
          <div className="col-auto my-3 mx-3 single-client">
            <img alt="Compass" src={compassImg} />
          </div>
          <div className="col-auto my-3 mx-3 single-client">
            <img alt="Provencred" src={PROImg} />
          </div>
          <div className="col-auto my-3 mx-3 single-client">
            <img alt="Sensebyte" src={senseImg} />
          </div>
          <div className="col-auto my-3 mx-3 single-client">
            <img alt="Sensebyte Mobile" src={sensemobileImg} />
          </div>
          <div className="col-auto my-3 mx-3 single-client">
            <img alt="The Mark Agency" src={tmaImg} />
          </div>
          <div className="col-auto my-3 mx-3 single-client">
            <img alt="Varix" src={varixImg} />
          </div>
        </div>
      </div>
    </section>
  );
}
